/* eslint-env jquery */

import { select2 } from 'select2'; // eslint-disable-line no-unused-vars

export class Dropdown {
    /**
     * Initialize a select2 dropdown.
     *
     * @param {string} dropdownId The HTML id of the panel.
     * @param {object} options Options for select2.
     */
    constructor(dropdownId, options) {
        this.dropdownId = dropdownId;

        if (!jQuery().select2) {
            return;
        }

        this.dropdown = jQuery(dropdownId).select2(options);
    }
}
