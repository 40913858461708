export class DateRange {
    /**
     * Initialize an mmenu light menu.
     *
     * @param {string} startDateId Id of the start date field.
     * @param {string} endDateId   Id of the end date field.
     */
    constructor(startDateId, endDateId) {
        this.startDateId = startDateId;
        this.endDateId = endDateId;

        // Find the date elements.
        this.startDate = document.getElementById(this.startDateId);
        this.endDate = document.getElementById(this.endDateId);

        // Make sure the menu exists.
        if (
            this.startDate instanceof HTMLElement === false ||
            this.endDate instanceof HTMLElement === false
        ) {
            return;
        }

        // Update date range validations on date field changes.
        this.startDate.addEventListener(
            'change',
            (event) => {
                this.syncMinimumEndDate();
            },
            false
        );
        this.endDate.addEventListener(
            'change',
            (event) => {
                this.syncMinimumEndDate();
            },
            false
        );

        // Initialize the date range validations based on starting values.
        this.syncMinimumEndDate();
    }

    /**
     * Base the start minimum and end maximum on the current values.
     */
    syncMinimumEndDate() {
        this.endDate.setAttribute('min', this.startDate.value);
        this.startDate.setAttribute('max', this.endDate.value);
    }
}
