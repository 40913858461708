/* eslint-env jquery */

import { OwlCarousel } from 'owl.carousel'; // eslint-disable-line no-unused-vars

export class Carousel {
    /**
     * Initialize an Owl Carousel carousel.
     *
     * @param {string} carouselId The HTML id of the panel.
     * @param {object} options Options for Owl Carousel.
     */
    constructor(carouselId, options) {
        this.carouselId = carouselId;

        if (!jQuery().owlCarousel) {
            return;
        }

        this.carousel = jQuery(carouselId).owlCarousel(options);
    }
}
