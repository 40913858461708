/* eslint-disable guard-for-in, max-lines, max-lines-per-function, max-statements, no-negated-condition, vars-on-top */
/* eslint-env browser, jquery */
/* global CKEDITOR */

(function($, window, document) {
    'use strict';

    var methods = {
        /**
         * Init.
         *
         * @param {object} options The plugin options.
         *
         * @returns {NodeList} The nodes the plugin was applied to.
         */
        init: function(options) {
            // default options
            var o = $.extend(
                {
                    noCORS: false,
                    errorPosition: 'top',
                    mobileErrorPosition: 'top',
                    mobileMaxwidth: 800,
                    submitOnSuccess: true,
                    showFieldErrors: true,
                    showErrorSummary: true,
                    showErrorSummaryWhenNoneVisible: true,
                    onValid: false,
                    onSuccess: false,
                    onSubmit: false,
                    scrollToError: true,
                    onInvalid: function() {},
                },
                options
            );

            return this.each(function() {
                var $this = $(this);
                var data = $this.data('formValidation');

                if (!data) {
                    var errorPosition = o.errorPosition;

                    if ($this.hasClass('error_position_left')) {
                        errorPosition = 'left';
                    } else if ($this.hasClass('error_position_right')) {
                        errorPosition = 'right';
                    } else if ($this.hasClass('error_position_top')) {
                        errorPosition = 'top';
                    } else if ($this.hasClass('error_position_bottom')) {
                        errorPosition = 'bottom';
                    }

                    var id = $this.attr('id') ? $this.attr('id').trim() : '';
                    var action = $this.attr('action')
                        ? $this.attr('action').trim()
                        : '';
                    var formClass = $this.attr('class')
                        ? $this.attr('class').trim()
                        : '';

                    var formId = formClass.split(' ')[0];

                    var method = o.noCORS ? 'GET' : 'POST';

                    $(this).data('formValidation', {
                        target: $this,
                        o: o,
                        submittable: true,
                        id: id,
                        action: action,
                        formId: formId,
                        errorPosition: errorPosition,
                        onValid: o.onValid,
                        onSuccess: o.onSuccess,
                        onInvalid: o.onInvalid,
                        onSubmit: o.onSubmit,
                        method: method,
                    });

                    if (method === 'GET') {
                        $this.prepend(
                            '<input type="hidden" name="_method" value="post" />'
                        );
                        $this.prepend(
                            '<input type="hidden" name="_ie9_cors" value="1" />'
                        );
                    }

                    // Override the enter key on inputs so the form
                    // doesn't get submitted incorrectly.
                    $this
                        .find('input, select')
                        .on('keydown.formValidation', function(e) {
                            if (e.which === 13) {
                                e.preventDefault();

                                if (
                                    $this.find(
                                        'input[name="form_ctrl[submit]"]'
                                    ).length !== 0
                                ) {
                                    $this
                                        .find('input[name="form_ctrl[submit]"]')
                                        .click();
                                } else {
                                    $this.submit();
                                }
                            }
                        });

                    // Track any button clicks so their values can be included in the query string.
                    // This is separated into 3 binds becasue using a comma in the find method to
                    // separate the elements causes an error in IE7.
                    $this
                        .find('input[type=submit]')
                        .on('click.formClickTracking', function() {
                            $this.formValidation('formClickTracking', $(this));
                        });
                    $this
                        .find('input[type=button]')
                        .on('click.formClickTracking', function() {
                            $this.formValidation('formClickTracking', $(this));
                        });
                    $this
                        .find('input[type=image]')
                        .on('click.formClickTracking', function() {
                            $this.formValidation('formClickTracking', $(this));
                        });

                    // Override the form submission so we can validate.
                    $this.on('submit.formValidation', function() {
                        return $this.formValidation('submit');
                    });
                }
            });
        },

        /**
         * Change the onSuccess method.
         *
         * @param {function()} onSuccess The on success callback.
         */
        setOnSuccess: function(onSuccess) {
            var $this = $(this);
            var data = $this.data('formValidation');
            data.onSuccess = onSuccess;
        },

        /**
         * Change the onInvalid method.
         *
         * @param {function()} onInvalid The on invalid callback.
         */
        setOnInvalid: function(onInvalid) {
            var $this = $(this);
            var data = $this.data('formValidation');
            data.onInvalid = onInvalid;
        },

        /**
         * Perform a regular submit and any event on/off
         * that is required to allow that to happen.
         */
        regularSubmit: function() {
            var $this = $(this);
            var data = $this.data('formValidation');

            // Remove the validation on submit so we can perform a regular submit.
            $this.off('submit.formValidation');

            // Perform a regular submit.
            if ($this.find('.__clicked__').length !== 0) {
                // Remove and unbind 'click' so that confirmation messages don't get shown twice.
                $this
                    .find('.__clicked__')
                    .prop('onclick', null)
                    .off('click')
                    .click();
            } else {
                $this.submit();
            }

            // Rebind submit validation and mark the form submittable
            // in case some other code also binds onSubmit and doesn't really
            // take the user away from the page.
            $this.on('submit.formValidation', function() {
                return $this.formValidation('submit');
            });
            data.submittable = true;
        },

        /**
         * Perform field validations on submit.
         *
         * @returns {boolean} Whether or not to proceed with submission.
         */
        submit: function() {
            var $this = $(this);
            var data = $this.data('formValidation');

            // Stop additional submission if on has already been initiated.
            if (data.submittable === false) {
                return false;
            }

            // Stop form from being resubmitted while background processing occurs.
            data.submittable = false;

            // Trigger any onSubmit event passed in the options.
            if (
                data.onSubmit !== false &&
                typeof data.onSubmit === 'function'
            ) {
                data.onSubmit();
            }

            // Remove old errors.
            $('.' + data.formId + '-error-summary').remove();
            $('.' + data.formId + '-error').remove();
            $this
                .find('.m6-form-validation-has-error')
                .removeClass('m6-form-validation-has-error');

            // Make sure ckeditors are synced.
            if (
                typeof CKEDITOR !== 'undefined' &&
                typeof CKEDITOR.instances !== 'undefined'
            ) {
                for (var instanceName in CKEDITOR.instances) {
                    CKEDITOR.instances[instanceName].updateElement();
                }
            }

            // Serialize the form data to use as a query string.
            var qs = $this.serialize();

            // Add any clicked buttons in the form to the query string.
            $this.find('.__clicked__').each(function() {
                if ($(this).attr('name')) {
                    var queryName = $(this)
                        .attr('name')
                        .trim();

                    var queryValue = $(this).val();

                    qs += '&' + encodeURIComponent(queryName);
                    qs += '=' + encodeURIComponent(queryValue);
                }
            });

            var ajaxSubmit = false;
            if (
                data.onSuccess !== false &&
                typeof data.onSuccess === 'function'
            ) {
                ajaxSubmit = true;
            }

            // Check field validations
            // js_only_validation=1 is passed so no data will actually be changed.
            $.ajax({
                method: data.method,
                url: data.action,
                data: qs + '&js_only_validation=1',
                error: function(jqXHR) {
                    $this.formValidation('handleErrors', jqXHR);
                },
                success: function(resp, textStatus, jqXHR) {
                    // Backward compatibility for IE8/9 CORS.
                    // The response can't be an actuall error code
                    // because then response data can't be retrieved.
                    if (
                        data.o.noCORS &&
                        ((typeof jqXHR.responseXML !== 'undefined' &&
                            $(jqXHR.responseXML).find('rest_exception')
                                .length !== 0) ||
                            (typeof jqXHR.responseJSON !== 'undefined' &&
                                typeof jqXHR.responseJSON.rest_exception !==
                                    'undefined'))
                    ) {
                        $this.formValidation('handleErrors', jqXHR);

                        return false;
                    }

                    // Don't submit the form for real.
                    // Check for validation, and optionaly perform
                    // an onSuccess() callback.
                    if (data.o.submitOnSuccess === false) {
                        if (ajaxSubmit === true) {
                            data.onSuccess(resp, data);
                        }
                        data.submittable = true;
                    } else if (ajaxSubmit === true) {
                        // Submit the form via ajax, and perform
                        // an onSuccess() callback with the response.
                        $.ajax({
                            method: data.method,
                            url: data.action,
                            data: qs,
                            success: function(ajaxSubmitResp) {
                                data.onSuccess(ajaxSubmitResp, data);
                                data.submittable = true;
                            },
                        });
                    } else {
                        // Submit the form as normal.
                        $this.formValidation('regularSubmit');
                    }

                    if (
                        data.onValid !== false &&
                        typeof data.onValid === 'function'
                    ) {
                        data.onValid();
                    }

                    return true;
                },
            });

            return false;
        },

        handleErrors: function(jqXHR) {
            var $this = $(this);
            var data = $this.data('formValidation');

            var arrErrs = {};
            var fieldName;
            var errorMessage;

            if (typeof jqXHR.responseXML !== 'undefined') {
                // Gather the errors from the XML returned by the API.
                $(jqXHR.responseXML)
                    .find('error')
                    .each(function() {
                        fieldName = $(this)
                            .children('field_name')
                            .text();
                        errorMessage = $(this)
                            .children('error_message')
                            .text();

                        if (!arrErrs[fieldName]) {
                            arrErrs[fieldName] = [];
                        }

                        arrErrs[fieldName].push(errorMessage);
                    });
            } else if (typeof jqXHR.responseJSON !== 'undefined') {
                // Gather the errors from the JSON returned by the API.
                var error;
                for (error in jqXHR.responseJSON.error) {
                    fieldName = jqXHR.responseJSON.error[error].field_name;
                    errorMessage =
                        jqXHR.responseJSON.error[error].error_message;

                    if (!arrErrs[fieldName]) {
                        arrErrs[fieldName] = [];
                    }

                    arrErrs[fieldName].push(errorMessage);
                }
            }

            // If the only error is an invalid XSRF-TOKEN change the form's
            // token value to the one returned and resubmit the form.
            var i;
            for (i in arrErrs) {
                if (i === 'XSRF-TOKEN') {
                    var newToken = arrErrs[i];
                    $this.find('input[name="XSRF-TOKEN"]').val(newToken);
                    data.submittable = true;
                    $this.submit();
                    return;
                }
            }

            // Show error messages.
            $this.formValidation('markErrors', arrErrs);
            data.onInvalid(arrErrs, data);
            data.submittable = true;
        },

        /**
         * Add validation errors messages to the form.
         *
         * @param {object} arrErrs Submission errors.
         */
        markErrors: function(arrErrs) {
            var $this = $(this);
            var data = $this.data('formValidation');

            var err;
            var fieldName;
            var i;
            var errorMsg;
            var existingErrorName;

            /**
             * Handle an error's blur event.
             */
            function onErrorBlur() {
                if ($(this).attr('name') && $(this).val()) {
                    $(this).removeClass('m6-form-validation-has-error');

                    var elementName = $(this)
                        .attr('name')
                        .trim();

                    existingErrorName =
                        '.' +
                        elementName.replace(/\[/g, '_').replace(/\]/g, '_') +
                        '-error';
                    $(existingErrorName).remove();
                }
            }

            /**
             * Handle a message close event.
             */
            function onMessageClose() {
                $(this)
                    .closest('.m6-form-validation-error-message')
                    .remove();
            }

            var showSummary = data.o.showErrorSummary;
            var hasVisibleError = false;

            // Keep track of the current window position and the error
            // message that is top most on the screen.
            var currentTop = $(window).scrollTop();
            var highestTop = currentTop;

            // Individual Field Error Messages
            for (fieldName in arrErrs) {
                var field = $this.find('[name="' + fieldName + '"]');

                // If a select box is replaced with a selectItBox fancy
                // select use that instead for error position.
                if (field.next('.selectboxit-container').length !== 0) {
                    field = field.next();
                }

                if (
                    field.length !== 0 &&
                    (field.is(':visible') ||
                        field.hasClass('form_style_overridden'))
                ) {
                    hasVisibleError = true;

                    // Build the error message element.
                    err = '';
                    if (arrErrs[fieldName].length === 1) {
                        // Put single errors in a span element.
                        err =
                            '<span class="m6-form-validation-form-field-error-msg">' +
                            arrErrs[fieldName][0] +
                            '</span>';
                    } else {
                        // Put multiple errors in a list element.
                        err =
                            '<ul class="m6-form-validation-form-field-error-msg-list">';
                        for (i in arrErrs[fieldName]) {
                            err +=
                                '<li class="m6-form-validation-form-field-error-msg">' +
                                arrErrs[fieldName][i] +
                                '</li>';
                        }
                        err += '</ul>';
                    }

                    var errorName =
                        fieldName.replace(/\[/g, '_').replace(/\]/g, '_') +
                        '-error ';
                    var errorIdClass =
                        'm6-form-validation-error-message ' +
                        data.formId +
                        '-error ';
                    errorMsg = $(
                        '<div class="' +
                            errorIdClass +
                            errorName +
                            '">' +
                            '<div class="m6-form-validation-error-message-content">' +
                            err +
                            '<a href="javascript:void(0);" class="m6-form-validation-error-message-close">&#215;</a>' +
                            '</div>' +
                            '<div class="m6-form-validation-error-message-arrow"></div>' +
                            '</div>'
                    );

                    // Add the error message to the page.
                    $('body').append(errorMsg);
                    field
                        .addClass('m6-form-validation-has-error')
                        .blur(onErrorBlur);

                    // Close the message when the close button is clicked.
                    errorMsg
                        .find('.m6-form-validation-error-message-close')
                        .click(onMessageClose);

                    errorMsg.data('field', field);

                    // Position the field error.
                    $this.formValidation('positionMessage', errorMsg);

                    // Keep track of the top most error message on the screen.
                    var top = errorMsg.css('top').replace('px', '') - 20;
                    if (top < highestTop) {
                        highestTop = top;
                    }
                } else {
                    showSummary = true;
                }

                // If the top most error message is not visible scroll up to show it.
                if (data.o.scrollToError === true && currentTop > highestTop) {
                    $(window).scrollTop(highestTop);
                }
            }

            if (
                data.o.showErrorSummary === true ||
                (data.o.showErrorSummaryWhenNoneVisible === true &&
                    showSummary === true &&
                    hasVisibleError === false)
            ) {
                // Summary of all errors.
                err =
                    '<ul class="m6-form-validation-form-field-error-msg-list">';
                for (fieldName in arrErrs) {
                    for (i in arrErrs[fieldName]) {
                        err +=
                            '<li class="m6-form-validation-form-field-error-msg">' +
                            arrErrs[fieldName][i] +
                            '</li>';
                    }
                }
                err += '</ul>';

                var errorSummaryClass =
                    'm6-form-validation-error-message--error-summary ' +
                    data.formId +
                    '-error-summary ';
                errorMsg = $(
                    '<div class="m6-form-validation-error-message ' +
                        errorSummaryClass +
                        '">' +
                        '<div class="m6-form-validation-error-message-content">' +
                        '<strong>There were problems with your submission.</strong>' +
                        err +
                        '<a href="javascript:void(0);" class="m6-form-validation-error-message-close">&#215;</a>' +
                        '</div>' +
                        '<div class="m6-form-validation-error-message-arrow"></div>' +
                        '</div>'
                );
                $('body').append(errorMsg);

                // Close the summary message and reveal the inline messages
                // when the summary close button is clicked.
                errorMsg
                    .find('.m6-form-validation-error-message-close')
                    .click(function() {
                        $(this)
                            .closest('.m6-form-validation-error-message')
                            .remove();
                        if (data.o.showFieldErrors === true) {
                            $('body')
                                .find('.' + data.formId + '-error')
                                .show();
                        }
                    });

                // Reveal the summary message and center it.
                errorMsg.show();
                $this.formValidation('centerMessage', errorMsg);
            } else if (data.o.showFieldErrors === true) {
                $('body')
                    .find('.' + data.formId + '-error')
                    .show();
            }
        },

        /**
         * Marks a clicked input button.
         *
         * This is needed so that only the clicked button is included
         * in the values returned by getFormHttpVars().  Otherwise it
         * would return all button values as if they were all clicked.
         *
         * @param {HTMLElement} clicked The clicked button element.
         */
        formClickTracking: function(clicked) {
            var $this = $(this);

            var buttonTypes =
                'input[type=submit], input[type=button], input[type=image]';
            $this.find(buttonTypes).removeClass('__clicked__');
            clicked.addClass('__clicked__');
        },

        /**
         * Center an element on the page.
         *
         * @param {HTMLElement} message The message to center.
         */
        centerMessage: function(message) {
            message.css('position', 'absolute');
            message.css(
                'top',
                Math.max(
                    0,
                    ($(window).height() - message.outerHeight()) / 2 +
                        $(window).scrollTop()
                ) + 'px'
            );
            message.css(
                'left',
                Math.max(
                    0,
                    ($(window).width() - message.outerWidth()) / 2 +
                        $(window).scrollLeft()
                ) + 'px'
            );
        },

        /**
         * Position a field error near it's field.
         *
         * @param {HTMLElement} errorMsg The message to position.
         */
        positionMessage: function(errorMsg) {
            var $this = $(this);
            var data = $this.data('formValidation');

            var field = errorMsg.data('field');

            // This is a hack to fix a bug where resize is bound each time an error is positioned.
            // If the form is submitted twice than the resize is bound to an element that has lost
            // it's data and will cause an error.
            //
            // TODO: The real solution to this should be to unbind the
            // resize event before rebinding it.
            if (typeof field === 'undefined') {
                return;
            }

            // Position the error message.
            var errorMsgOffset;
            if (field.hasClass('form_style_overridden')) {
                // If the form field has been replaced via javascript with other
                // controls use the offset of the replacement controls instead of
                // the field's real offset.
                errorMsgOffset = field
                    .siblings('.form_style_override')
                    .offset();
            } else {
                // Get the offset of the form field.
                errorMsgOffset = field.offset();
            }

            var errorPosition = data.errorPosition;
            if ($(window).width() < data.o.mobileMaxwidth) {
                errorPosition = data.o.mobileErrorPosition;
            }

            switch (errorPosition) {
                case 'top':
                    // Move the message up the page a distance equal to its height
                    // to put it above the field.
                    errorMsgOffset.top -= errorMsg.height();
                    break;

                case 'left':
                    // Move the message up the page a distance equal to its height
                    // to put it above the field.
                    errorMsgOffset.left -= errorMsg.width() + 5;
                    break;

                case 'right':
                    // Move the message to the right the width of the field plus
                    // a little padding to put it to the right of the field.=
                    var fieldWidth = field.outerWidth();
                    if (field.closest('.field-wrapper').length !== 0) {
                        fieldWidth = field.closest('.field-wrapper').width();
                    } else if (field.closest('.field-container').length !== 0) {
                        fieldWidth = field.closest('.field-container').width();
                    } else if (field.closest('label').length !== 0) {
                        fieldWidth = field.closest('label').width();
                    }
                    errorMsgOffset.left += fieldWidth + 5;
                    break;

                default:
                    // Do Nothing
                    break;
            }
            errorMsg.css('left', errorMsgOffset.left + 'px');
            errorMsg.css('top', errorMsgOffset.top + 'px');
            errorMsg
                .removeClass('m6-form-validation-error-message--pos-top')
                .removeClass('m6-form-validation-error-message--pos-left')
                .removeClass('m6-form-validation-error-message--pos-right')
                .removeClass('m6-form-validation-error-message--pos-bottom')
                .addClass(
                    'm6-form-validation-error-message--pos-' + errorPosition
                );

            /**
             * Handle a window resize event.
             */
            function doResize() {
                $this.formValidation('positionMessage', errorMsg);
            }
            var onAfterResize;
            $(window).resize(function() {
                clearTimeout(onAfterResize);
                onAfterResize = setTimeout(doResize, 100);
            });
        },
    };

    $.fn.formValidation = function(method) {
        if (methods[method]) {
            return methods[method].apply(
                this,
                Array.prototype.slice.call(arguments, 1)
            );
        }

        if (typeof method === 'object' || !method) {
            return methods.init.apply(this, arguments);
        }

        $.error(
            'Method ' + method + ' does not exist on jQuery.formValidation'
        );

        return false;
    };

    // Clicking anywhere on the page should close the
    // summary errors and show the inline errors.
    $(document).click(function() {
        $('.m6-form-validation-error-message--error-summary')
            .find('.m6-form-validation-error-message-close')
            .click();
    });
})(jQuery, window, document);
