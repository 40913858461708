/* eslint-env jquery */

import 'bootstrap.native'; // eslint-disable-line import/no-unassigned-import

import { M6FormValidation } from 'M6/form_validation/jquery.form_validation'; // eslint-disable-line no-unused-vars, import/no-unresolved, import/extensions

import { Carousel } from './carousel';
import { DateRange } from './date-range';
import { Dropdown } from './dropdown';
import { JqueryMobileMenu } from './jquery-mobile-menu';
// import { Table } from './table';
// import { MobileMenu } from './mobile-menu';

export class App {
    /**
     * Initialize the app on dom content loaded.
     */
    // eslint-disable-next-line max-lines-per-function
    dom() {
        // M6 Form Validation
        // ---------------------------------------------------------------------
        jQuery(($) => {
            if (!$().formValidation) {
                return;
            }

            $('.arForm:not(.arFormNoValidation)').formValidation({
                errorPosition: 'right',
            });
        });

        // Mobile Menu
        // ---------------------------------------------------------------------
        // document.addEventListener('DOMContentLoaded', () => {
        //     const mobileMenu = new MobileMenu('#mobile-menu-panel', {
        //         position: 'right',
        //     });
        //     mobileMenu.bindMenuToggle();
        // });

        // Mobile Menu
        // ---------------------------------------------------------------------
        document.addEventListener('DOMContentLoaded', () => {
            // eslint-disable-next-line no-unused-vars
            const mobileMenu = new JqueryMobileMenu(
                '#mobile-menu-panel',
                {
                    // Options
                    extensions: [
                        // Make the menu open on the right instead of left.
                        'position-right',
                    ],
                },
                {
                    // Configuration
                }
            );
        });

        // Success Stories Carousel
        // ---------------------------------------------------------------------
        document.addEventListener('DOMContentLoaded', () => {
            // eslint-disable-next-line no-unused-vars
            const successStoresCarousel = new Carousel('.owl-carousel', {
                loop: true,
                margin: 10,
                nav: true,
                items: 1,
                navText: [
                    "<i class='fas fa-chevron-left'></i><span>Previous</span>",
                    "<span>Next</span><i class='fas fa-chevron-right'></i>",
                ],
            });
        });

        // Sortable Table Without Keywords
        // ---------------------------------------------------------------------
        // jQuery(($) => {
        //     // eslint-disable-next-line no-unused-vars
        //     const decisionDatabaseTableWithoutKeywords = new Table(
        //         '.decision-search-results-table--without-keywords',
        //         {
        //             autoWidth: false,
        //             columnDefs: [
        //                 {
        //                     orderable: false,
        //                     targets: [6],
        //                 },
        //             ],
        //             order: [],
        //             dom: 'lrtip',
        //             pageLength: 25,
        //             responsive: false,
        //         }
        //     );
        // });

        // Sortable Table With Keywords
        // ---------------------------------------------------------------------
        // jQuery(($) => {
        //     // eslint-disable-next-line no-unused-vars
        //     const decisionDatabaseTableWithKeywords = new Table(
        //         '.decision-search-results-table--with-keywords',
        //         {
        //             autoWidth: false,
        //             columnDefs: [
        //                 {
        //                     className: 'details-control',
        //                     // data: null,
        //                     // defaultContent: '',
        //                     orderable: false,
        //                     targets: [0],
        //                 },
        //                 {
        //                     orderable: false,
        //                     targets: [7],
        //                 },
        //             ],
        //             order: [],
        //             dom: 'lrtip',
        //             pageLength: 25,
        //             responsive: false,
        //         }
        //     );
        //     /**
        //      * Formats additional row data for display.
        //      *
        //      * @param {string} name The additional data name.
        //      * @param {string} value Value The additional data value.
        //      *
        //      * @returns {string} The row display data.
        //      */
        //     function formatSearchResultAdditional(name, value) {
        //         return `
        //             <div class="decision-search-results-table__text-excerpt">
        //                 <strong>${name}</strong> ${value}
        //             </div>
        //         `;
        //     }
        //     /* eslint-disable max-statements */
        //     // Add event listener for opening and closing details
        //     $('.decision-search-results-table--with-keywords tbody').on(
        //         'click',
        //         'td.details-control',
        //         (event) => {
        //             event.preventDefault();
        //             const td = $(event.currentTarget);
        //             const tr = $(td).closest('tr');
        //             const row = decisionDatabaseTableWithKeywords.table.row(tr);
        //             if (row.child.isShown()) {
        //                 // This row is already open - close it
        //                 row.child.hide();
        //                 tr.removeClass('shown');
        //                 $(td)
        //                     .find('.fa-minus-circle')
        //                     .addClass('fa-plus-circle')
        //                     .removeClass('fa-minus-circle');
        //                 $(td)
        //                     .find('span')
        //                     .text('Collapse');
        //             } else {
        //                 // Open this row
        //                 row.child(
        //                     formatSearchResultAdditional(
        //                         tr.data('childName'),
        //                         tr.data('childValue')
        //                     )
        //                 ).show();
        //                 tr.addClass('shown');
        //                 $(td)
        //                     .find('.fa-plus-circle')
        //                     .addClass('fa-minus-circle')
        //                     .removeClass('fa-plus-circle');
        //                 $(td)
        //                     .find('span')
        //                     .text('Expand');
        //             }
        //         }
        //     );
        //     /* eslint-enable max-statements */
        // });

        // Server Side Table Expandable Row
        // ---------------------------------------------------------------------
        jQuery(($) => {
            $('.expanded-row-toggle').click((event) => {
                event.preventDefault();

                const td = $(event.currentTarget).closest('td');
                const tr = $(td).closest('tr');

                if (tr.next().is(':visible')) {
                    tr.next().hide();
                    $(td)
                        .find('.fa-minus-circle')
                        .addClass('fa-plus-circle')
                        .removeClass('fa-minus-circle');
                    $(td)
                        .find('span')
                        .text('Collapse');
                } else {
                    tr.next().show();
                    $(td)
                        .find('.fa-plus-circle')
                        .addClass('fa-minus-circle')
                        .removeClass('fa-plus-circle');
                    $(td)
                        .find('span')
                        .text('Expand');
                }
            });
        });

        // Advanced Drop Down
        // ---------------------------------------------------------------------
        document.addEventListener('DOMContentLoaded', () => {
            // eslint-disable-next-line no-unused-vars
            const categoryFilterDropdown = new Dropdown('#filter-category_id', {
                width: 'resolve',
            });

            // eslint-disable-next-line no-unused-vars
            const schoolDistrictFilterDropdown = new Dropdown(
                '#filter-school_district_id',
                {
                    allowClear: true,
                    placeholder: 'Select LEA',
                    width: 'resolve',
                }
            );

            // eslint-disable-next-line no-unused-vars
            const iuFilterDropdown = new Dropdown('#filter-iu_id', {
                allowClear: true,
                placeholder: 'Select IU',
                width: 'resolve',
            });

            // eslint-disable-next-line no-unused-vars
            const hearingOfficerFilterDropdown = new Dropdown(
                '#filter-hearing_officer_id',
                {
                    allowClear: true,
                    placeholder: 'Select Hearing Officer',
                    width: 'resolve',
                }
            );

            // eslint-disable-next-line no-unused-vars
            const searchPerPgDropdown = new Dropdown('.search-per-pg', {
                minimumResultsForSearch: -1,
                width: 'resolve',
            });
        });

        // Date Range Validation
        // ---------------------------------------------------------------------
        document.addEventListener('DOMContentLoaded', () => {
            // eslint-disable-next-line no-unused-vars
            const searchDateRange = new DateRange(
                'filter-start_date',
                'filter-end_date'
            );
        });

        // Decision Search Instructions
        // ---------------------------------------------------------------------
        jQuery(($) => {
            $('.decision-search-instructions__read-more').click((event) => {
                event.preventDefault();

                const instructions = $(event.currentTarget).siblings(
                    '.decision-search-instructions__instructions'
                );

                $(instructions).toggleClass(
                    'decision-search-instructions__instructions--expanded'
                );

                const expanded = $(instructions).hasClass(
                    'decision-search-instructions__instructions--expanded'
                );

                $(instructions).attr('aria-expanded', expanded);

                if (expanded) {
                    $(event.currentTarget).text('Read Less');
                } else {
                    $(event.currentTarget).text('Read More');
                }
            });
        });

        // Search Form
        // ---------------------------------------------------------------------
        document.addEventListener('DOMContentLoaded', () => {
            document.getElementById('search').classList.add('preloaded');
        });

        jQuery(($) => {
            // Open search button.
            $('a[href="#search"]').click((event) => {
                event.preventDefault();
                $('#search').addClass('open');
                $('#search > form > input[type="search"]').focus();
            });

            // Close search button.
            $('#search button.close').click((event) => {
                event.preventDefault();
                $('#search').removeClass('open');
            });

            // Close search if escape key is pressed.
            $(document).keyup((event) => {
                if (event.keyCode == 27) {
                    event.preventDefault();
                    $('#search').removeClass('open');
                }
            });

            // Submit search.
            $('#searchForm').submit((event) => {
                if (
                    !$(event.currentTarget)
                        .children('input[name="s"]')
                        .val()
                ) {
                    event.preventDefault();
                }
            });
        });

        // Resources Accordion
        // ---------------------------------------------------------------------
        jQuery(($) => {
            $('.accordion .resource-section h2').append('<i class="fas fa-plus-circle"></i>');
            $('.resource-section h2').click((event) => {
                console.log('click');
                event.preventDefault();

                const td = $(event.currentTarget).closest('div');
                const tr = $(td).children('div');

                if (tr.is(':visible')) {
                    tr.hide();
                    $(td)
                        .find('.fa-minus-circle')
                        .addClass('fa-plus-circle')
                        .removeClass('fa-minus-circle');
                } else {
                    tr.show();
                    $(td)
                        .find('.fa-plus-circle')
                        .addClass('fa-minus-circle')
                        .removeClass('fa-plus-circle');
                }
            });
        });

        // Smooth Scroll
        // ---------------------------------------------------------------------
        jQuery(($) => {
            $(".single-resource .top, .single-resource .top-top").on("click", function (e) {
                e.preventDefault();
                const href = $(this).attr("href");
                $("html, body").animate({ scrollTop: $(href).offset().top }, 500);
            });
        });


    }
}
